import { useCallback } from 'react';

import useGetOrder from './useGetOrder';

import * as OrderAPI from 'shared/infra/commerceLayer/orders';
import {
  getAttributionParams,
  getTrackingParams,
} from 'shared/services/attribution';
import { saveLocalOrderInformation } from 'shared/services/OrderInformationService';
import {
  dispatchOrderCreateEvent,
  dispatchOrderCreateFailureEvent,
} from 'shared/services/tracker/events';
import { useTypedSelector } from 'shared/store';

export interface CreateOrderReq {
  couponCode?: string;
  languageCode?: string;
}

export const useCreateOrder = () => {
  const getOrder = useGetOrder();

  const order = useTypedSelector((s) => s.order.orderDetails);

  const attrParams = getAttributionParams();
  const trackParams = getTrackingParams();

  const createOrder = useCallback(
    async (req: CreateOrderReq) => {
      try {
        const order = await OrderAPI.createOrder(
          req.couponCode,
          req.languageCode,
          attrParams,
          trackParams,
        );

        saveLocalOrderInformation(order.id);
        dispatchOrderCreateEvent({ is_referral: !!attrParams.referral_code });

        return getOrder(order.id);
      } catch (err) {
        dispatchOrderCreateFailureEvent({
          is_referral: !!attrParams.referral_code,
        });

        throw err;
      }
    },
    [attrParams, getOrder, trackParams],
  );

  const getOrCreateLocalOrder = useCallback(
    async (req: CreateOrderReq) => {
      if (order.id) {
        return order;
      }

      return createOrder(req);
    },
    [order, createOrder],
  );

  return { createOrder, getOrCreateLocalOrder };
};
