import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Headline, spacing, Spinner } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import { useAuth } from '@sumup/react-nanoauth';

import { useTypedSelector } from 'shared/store';
import usePromotions from 'shared/hooks/promotions/usePromotions';
import { redirectToSSOSignup } from 'shared/sso/RedirectService';
import { ShopExperience } from 'shared/constants/ShopExperience';
import { saveLocalOrderInformation } from 'shared/services/OrderInformationService';
import { IProductDetails } from 'productSelection/types/products';
import { useAddProduct } from 'shared/hooks/orders/useAddProduct';
import { ODLExperimentContext } from 'src/experiments/odl/context';
import {
  ODL_EXPERIMENT,
  VARIATION,
} from 'shared/services/optimizely/constants';
import { getItem } from 'shared/infra/storage';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  flex-direction: column;
  text-align: center;
`;

export interface ICreateOrderPageProps {
  products: IProductDetails[];
  commerceLayerClient: string;
  marketReference: string;
  content: {
    loadingText: string;
    orderSummaryTitle: string;
  };
}

export const CreateOrderPage = ({
  products,
  marketReference,
  content,
}: ICreateOrderPageProps): JSX.Element => {
  const [isODLExperimentEnabled, setIsODLExperimentEnabled] = useState(false);

  useEffect(() => {
    const odlExperimentVariation = getItem(ODL_EXPERIMENT.id);
    const isVariation = odlExperimentVariation === VARIATION;
    setIsODLExperimentEnabled(isVariation);
  }, []);
  const { authRedirect, authState } = useAuth();
  const { addProduct } = useAddProduct();
  const addToCartTrack = useRef(false);

  const { query, locale, push, replace, isReady } = useRouter();
  const promotions = usePromotions({
    locale,
    marketReference,
  });
  const { operationStatus, orderDetails } = useTypedSelector(
    (state) => state.order,
  );

  useEffect(() => {
    // reset state
    localStorage.clear();

    // do nothing if client is not ready or promotions not fetched
    if (!isReady || promotions.loading) {
      return;
    }

    const productTrackingId = query['product-id'];
    if (!productTrackingId) {
      replace({
        pathname: '/product-selection',
      });
      return;
    }

    // get product from the list based on query param
    const selectedProduct = products.find(
      (product) => product.trackingId === productTrackingId,
    );

    if (!selectedProduct) {
      replace({
        pathname: '/product-selection',
      });
      return;
    }

    // add product to cart
    if (selectedProduct && !addToCartTrack.current) {
      const coupon = promotions.details[selectedProduct.sku[0]];

      addProduct({
        sku: selectedProduct.sku,
        type: selectedProduct.type,
        quantity: 1,
        couponCode: coupon?.couponCode,
        locale: locale,
        contentfulId: selectedProduct.id,
        allowOnlyOne: true,
      });

      addToCartTrack.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotions, addToCartTrack]);

  // redirect to SSO
  useEffect(() => {
    if (!operationStatus.loading && !!orderDetails.id) {
      // persist required order info
      saveLocalOrderInformation(orderDetails.id);

      redirectToSSOSignup({
        query,
        locale,
        push,
        authRedirect,
        authState,
        source: ShopExperience.SIGNUP,
        redirectPathname: '/checkout/address',
        requestParams: {
          iframe_uri: getOrderIFrameUrl(orderDetails.id, locale),
          iframe_title: content.orderSummaryTitle,
        },
      });
    }
  }, [
    authRedirect,
    authState,
    content.orderSummaryTitle,
    locale,
    operationStatus,
    orderDetails,
    push,
    query,
  ]);

  return (
    <ODLExperimentContext.Provider
      value={{ isEnabled: isODLExperimentEnabled }}
    >
      <SpinnerWrapper>
        <Spinner size="giga" />
        <br />
        <Headline as="h2" size="two" css={spacing({ bottom: 'mega' })}>
          {content.loadingText}
        </Headline>
      </SpinnerWrapper>
    </ODLExperimentContext.Provider>
  );
};

export function getOrderIFrameUrl(orderId: string, locale: string) {
  return `${window.location.origin}/${locale}/order-overview/${orderId}`;
}
