import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useCreateOrder } from './useCreateOrder';
import useGetOrder from './useGetOrder';
import * as AddProductService from './AddProductService';

import { getLanguageFromISOLocale } from 'shared/i18n/helpers';
import { useTypedSelector } from 'shared/store';
import {
  addProductFailure,
  addProductSuccess,
  addProduct as addProductAction,
} from 'shared/store/order/actions';
import { processCouponCodeToOrder } from 'shared/infra/commerceLayer/utils';

export const useAddProduct = () => {
  const { getOrCreateLocalOrder } = useCreateOrder();
  const getOrder = useGetOrder();
  const dispatch = useDispatch();

  const products = useTypedSelector((s) => s.order.products);
  const catalog = useTypedSelector((s) => s.catalog);

  const addProduct = useCallback(
    async (req: AddProductService.AddProductRequest) => {
      dispatch(addProductAction(req.contentfulId));

      try {
        const languageCode = getLanguageFromISOLocale(req.locale);

        // TODO: Create a new order during initialization
        // to speed up the process of adding a product.
        const ensuredOrder = await getOrCreateLocalOrder({
          couponCode: processCouponCodeToOrder(req.couponCode),
          languageCode,
        });

        await AddProductService.addProduct(
          ensuredOrder.id,
          req,
          products,
          catalog,
        );

        dispatch(addProductSuccess('Product added to the cart.'));

        return getOrder(ensuredOrder.id);
      } catch (err) {
        dispatch(
          addProductFailure('Failure while adding a product to the order.'),
        );

        throw err;
      }
    },
    [catalog, dispatch, getOrder, products, getOrCreateLocalOrder],
  );

  return { addProduct };
};
